require('./init-svg4everybody');
require('./init-global');
require('./init-menus');
require('./init-results-map');
require('./init-cookie-popup');
require('./init-key-focus');
require('./init-additional-details');
require('./init-header');
require('./init-object-fit');
require('./init-lazy-load');
require('./init-scroller-nav');
require('./init-social-share');
require('./init-tabs');
require('./init-tabbed-features');

require('./init-subnav');
require('./init-brochure-dropdown');
require('./init-development-advanced-search');
require('./init-property-search-bar');

if (document.querySelector('.js-search-input')) {
  require('./init-typeahead');
}

if (document.querySelector('.js-contact-modal-btn')) {
  require('./init-contact-modal');
}

if (document.querySelector('.js-accordion-section')) {
  require('./init-accordion');
}

if (document.querySelector('.advanced-search')) {
  require('./init-advanced-search');
}

if (document.querySelector('input[name="NumberOfApplicants"]')) {
  require('./init-application-form');
}

if (document.querySelector('.js-apply-for-staircasing')) {
  require('./init-apply-to-sell-button');
}

if (document.querySelector('.js-blog-listing-grid')) {
  require('./init-blog-listing');
}

if (document.querySelector('.js-faq-listing')) {
  require('./init-faq-listing');
}

if (
  document.querySelector('.js-filter-btn') ||
  document.querySelector('.js-download-btn') ||
  document.querySelector('.js-location-filter-btn')
) {
  require('./init-filter-dropdown');
}

if (document.querySelector('.js-featured-table')) {
  require('./init-featured-table');
}

if (
  document.querySelector('.js-container-animate') ||
  document.querySelector('.js-lazy-bg') ||
  document.querySelector('.js-lazy-img') ||
  document.querySelector('.js-lazy-iframe')
) {
  require('./init-lazy-load');
}

if (document.getElementById('LocationMapLegacy')) {
  require('./init-location-map-legacy');
}

if (document.querySelector('.js-location-map') || document.querySelector('#LocationMap')) {
  require('./init-location-map');
}

if (document.querySelector('.js-btn-login')) {
  require('./init-login');
}

if (document.querySelector('.js-modal')) {
  require('./init-modal');
}

if (document.querySelector('.js-mortgage-calculator')) {
  require('./init-mortgage-calculator');
}

if (document.querySelector('.js-object-fit')) {
  require('./init-object-fit');
}

if (document.querySelector('.js-popup-btn')) {
  require('./init-popup');
}

if (document.querySelector('.js-quick-eligibility-check')) {
  require('./init-quick-eligibility-check');
}

if (document.querySelector('.js-register-interest-btn')) {
  require('./init-register-interest-button');
}

if (
  document.getElementById('#ApplicationType:visible') ||
  document.querySelector('.js-home-borough') ||
  document.querySelector('.js-work-borough')
) {
  require('./init-registration-form');
}

if (document.querySelector('.js-saved-property-btn')) {
  require('./init-saved-property-button');
}

if (document.querySelector('.js-scroller-nav')) {
  require('./init-scroller-nav');
}

if (document.querySelector('.js-search-type-selector')) {
  require('./init-search-bar');
}

if (document.querySelector('.js-site-search-toggle')) {
  require('./init-search-modal');
}

if (
  document.querySelector('.js-content-carousel') ||
  document.querySelector('.js-gallery-carousel') ||
  document.querySelector('.js-banner-carousel') ||
  document.querySelector('.js-carousel') ||
  document.querySelector('.js-card-carousel') ||
  document.querySelector('.js-blog-card-carousel') ||
  document.querySelector('.js-dev-gal-carousel') ||
  document.querySelector('.js-featured-developments-carousel') ||
  document.querySelector('.js-statistic-carousel') ||
  document.querySelector('.js-testimonial-carousel') ||
  document.querySelector('.js-gallery-carousel-resale')
) {
  require('./init-tiny-slider');
}

if (
  document.querySelector('.js-distance-slider') ||
  document.querySelector('.js-bedroom-slider') ||
  document.querySelector('.js-loan-slider')
) {
  require('./init-slider');
}

if (document.querySelector('.js-apply-to-sell')) {
  require('./init-staircasing-apply-button');
}

if (document.querySelector('.js-tab-nav-item')) {
  require('./init-tab-nav');
}

if (document.querySelector('.js-beer-slider')) {
  require('./init-beer-slider');
}

if (document.querySelector('.js-section-nav--spy')) {
  require('./init-step-navigation');
}

if (document.querySelector('.js-sticky-register-btn')) {
  require('./init-sticky-register');
}

if (document.querySelector('.js-ways-to-buy-nav')) {
  require('./init-specific-grids-content');
}

if (document.querySelector('.js-scroll-to-error')) {
  require('./init-scroll-to-error');
}

if (document.querySelector('.js-register-more-form')) {
  require('./init-register-expand');
}

if (document.querySelector('.js-count-up-container')) {
  require('./init-count-up');
}

if (document.querySelector('.js-tenure-select')) {
  require('./init-tenure-select');
}

if (document.querySelector('.js-home-banner-lottie')) {
  require('./init-home-banner-lottie');
}

require('./init-lightbox');
